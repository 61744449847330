import React from "react";
import {
  FaCalendarAlt,
  FaDribbble,
  FaEnvelopeOpenText,
  FaFacebookF,
  FaLinkedinIn,
  FaMapMarkerAlt,
  FaMobileAlt,
  FaTwitter,
} from "react-icons/fa";
import img from "../assets/images/about/avatar-dark.jpg";
import imgLight from "../assets/images/about/avatar-light-2.jpg";
import downloadIcon from "../assets/images/download.png";
import logoLight from "../assets/images/logo/logo-only-frame.png";
import logo from "../assets/images/logo/logo-only-frame-grey.png";
import UseData from "../Hooks/UseData";

const HomeCard = () => {
  const { handleTheme, check, menuItemTwo, NavLink } = UseData();

  return (
    <div>
      <div className="w-full mb-6 lg:mb-0  mx-auto   relative bg-white text-center dark:bg-[#111111] px-2 md:px-6 rounded-[20px] mt-[180px] md:mt-[220px] lg:mt-0 ">
        {!check ? (
            <img
                src={imgLight}
                className="w-[240px] absolute left-[50%] border-8 border-white transform -translate-x-[50%] h-[240px] drop-shadow-xl mx-auto  rounded-[20px] -mt-[140px]"
                alt=""
            />
        ) : (
            <img
                src={img}
                className="w-[240px] absolute left-[50%] border-8 border-color-970 transform -translate-x-[50%] h-[240px] drop-shadow-xl mx-auto  rounded-[20px] -mt-[140px]"
                alt=""
            />
        )}

        {/* Social card */}
        <div className="pt-[100px] pb-8">
          <h1 className="mt-6 mb-1 text-5xl font-semibold  dark:text-white">
            Haowei
          </h1>
          <h3 className="mb-4 text-[#7B7B7B] inline-block dark:bg-[#1D1D1D] px-5 py-1.5 rounded-lg dark:text-[#A6A6A6]  ">
            Software Developer
          </h3>

          {/*/!* Social Links *!/*/}

          {/*<div className="flex justify-center space-x-3">*/}
          {/*  /!* facebook link add here *!/*/}
          {/*  <a*/}
          {/*    href="https://www.facebook.com/"*/}
          {/*    target="_blank"*/}
          {/*    rel="noopener noreferrer"*/}
          {/*  >*/}
          {/*    <span className="socialbtn text-[#1773EA]">*/}
          {/*      <FaFacebookF />*/}
          {/*    </span>*/}
          {/*  </a>*/}
          {/*  /!* twitter link add here *!/*/}
          {/*  <a*/}
          {/*    href="https://twitter.com/"*/}
          {/*    target="_blank"*/}
          {/*    rel="noopener noreferrer"*/}
          {/*  >*/}
          {/*    <span className="socialbtn text-[#1C9CEA]">*/}
          {/*      <FaTwitter />*/}
          {/*    </span>*/}
          {/*  </a>*/}
          {/*  /!* drop link add here *!/*/}
          {/*  <a*/}
          {/*    href="https://dribbble.com/"*/}
          {/*    target="_blank"*/}
          {/*    rel="noopener noreferrer"*/}
          {/*  >*/}
          {/*    <span className="socialbtn text-[#e14a84] ">*/}
          {/*      <FaDribbble />*/}
          {/*    </span>*/}
          {/*  </a>*/}
          {/*  /!* linkedin link add here *!/*/}
          {/*  <a*/}
          {/*    href="https://www.linkedin.com/"*/}
          {/*    target="_blank"*/}
          {/*    rel="noopener noreferrer"*/}
          {/*  >*/}
          {/*    <span className="socialbtn text-[#0072b1]">*/}
          {/*      <FaLinkedinIn />*/}
          {/*    </span>*/}
          {/*  </a>*/}
          {/*</div>*/}

          {/* personal information */}
          <div className="p-5 md:p-7 rounded-2xl mt-7 bg-[#F3F6F6] dark:bg-[#1D1D1D]">
            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] pb-2.5">
              <span className="socialbtn bg-dark light:bg-white text-[#E93B81] shadow-md">
                <FaMobileAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Phone & Whatsapp
                </p>
                <p className="dark:text-white text-base">+62 856 6834 1706</p>
              </div>
            </div>
            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
              <span className="socialbtn bg-dark light:bg-white text-[#6AB5B9] shadow-md">
                <FaEnvelopeOpenText />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Email
                </p>
                <p className="dark:text-white text-base">inquiry@haowei.dev</p>
              </div>
            </div>
            <div className="flex border-b border-[#E3E3E3] dark:border-[#3D3A3A] py-2.5">
              <span className="socialbtn bg-dark light:bg-white text-[#FD7590] shadow-md">
                <FaMapMarkerAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Location
                </p>
                <p className="dark:text-white text-base">Batam, Indonesia</p>
              </div>
            </div>
            <div className="flex  py-2.5">
              <span className="socialbtn bg-dark light:bg-white text-[#C17CEB] shadow-md">
                <FaCalendarAlt />
              </span>
              <div className="text-left ml-2.5">
                <p className="text-xs text-[#44566C] dark:text-[#A6A6A6]">
                  Birthday
                </p>
                <p className="dark:text-white text-base">March 22</p>
              </div>
            </div>
          </div>
          {/*<button className="flex items-center mx-auto bg-gradient-to-r from-[#FA5252] to-[#DD2476] duration-200 transition ease-linear hover:bg-gradient-to-l from-[#DD2476]  to-[#fa5252ef] px-8 py-3 text-lg text-white rounded-[35px] mt-6">*/}
          {/*  <img src={downloadIcon} alt="icon" className="mr-2" />*/}
          {/*  Download CV*/}
          {/*</button>*/}
        </div>
      </div>
    </div>
  );
};

export default HomeCard;
